export const data = [
    { category: 'blog posts', title: 'Innovated interactions that speaks volumes', date: 'Mar 1 . 4 min read', },
    { category: 'blog posts', title: 'aDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'blog posts', title: 'hDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'blog posts', title: 'zDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'blog posts', title: 'bDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'blog posts', title: 'DALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'blog posts', title: 'IRubric', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...', date: 'Feb 24 . 5 min read', },
    { category: 'blog posts', title: 'gDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'blog posts', title: 'gDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'hDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'kDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'qDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'aDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'bDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'hDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'kDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'tDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
    { category: 'Case studies', title: 'gsdDALLAS LEADERS', description: 'MAG-RAW Creations Awarded by Clutch as One of the Top Explainer Video Companies in Dallas 2021', date: 'Mar 8 . 6 min read', },
]
